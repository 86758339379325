import * as Yup from "yup";

export type SCHEMA_EMAILPREF_VALUES = {
  email: string;
  filters: {
    interestAreas: string;
    suitabilities: string;
  };
};

export type SCHEMA_EMAILPREF_TOUCHED = {
  email?: boolean;
  filters?: {
    interestAreas?: boolean;
    suitabilities?: boolean;
  };
};

export type SCHEMA_EMAILPREF_ERRORS = {
  email?: string;
  filters?: {
    interestAreas?: string;
    suitabilities?: string;
  };
};

export const SCHEMA_EMAILPREF_FILTERS = Yup.object({
  interestAreas: Yup.string().required(
    "Please select at least one area of interest"
  ),
  suitabilities: Yup.string().required("Please select an age bracket"),
});

export const SCHEMA_EMAILPREF_FORM = Yup.object({
  url: Yup.string().label("URL"),
  email: Yup.string().email().required("Please provide your email adress"),
  filters: SCHEMA_EMAILPREF_FILTERS,
}).required();
