import React, { useState, useEffect } from "react";
import { useLocation, navigate } from "@reach/router";
import { useForm } from "react-yup";
import toast from "react-hot-toast";
import { Link } from "gatsby";

import { Form } from "~/components";
import { Layout, Meta, Hero, Section } from "~/ui";
import { useAreasOfInterest, useEmailPreferences } from "~/utils";
import { ApiProvider, QueryProvider, createApi, WidgetApiUrl } from "~/api";
import { IEmailPreferencesRequest } from "~/types";
import { Routes } from "~/models";
import { QUERYSTRING, SUITABILITIES } from "~/constants";

import {
  SCHEMA_EMAILPREF_VALUES,
  SCHEMA_EMAILPREF_TOUCHED,
  SCHEMA_EMAILPREF_ERRORS,
  SCHEMA_EMAILPREF_FORM,
} from "~/schemas";

export default function () {
  const api = createApi(process.env.GATSBY_PUBLIC_ID || "");

  return (
    <ApiProvider api={api}>
      <QueryProvider>
        <UpdateEmailPreferences />
      </QueryProvider>
    </ApiProvider>
  );
}

const UpdateEmailPreferences = (): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get(QUERYSTRING.ID);
  if (!token) navigate(Routes.SignUp);

  const { data: areasOfInterest } = useAreasOfInterest();
  const subscribe = useEmailPreferences();
  let [submitting, setSubmitting] = useState<boolean>(false);

  const areaOfInterestList = (areasOfInterest || []).map((n) => ({
    value: `${n.id}`,
    text: n.name,
  }));

  const form = useForm({
    validationSchema: SCHEMA_EMAILPREF_FORM,
    defaultValues: {
      filters: {
        interestAreas: "",
        suitabilities: "",
      },
      email: "",
    } as SCHEMA_EMAILPREF_VALUES,
  });

  const values = form.values as SCHEMA_EMAILPREF_VALUES;
  const touched = form.touched as SCHEMA_EMAILPREF_TOUCHED;
  const errors = form.errors as SCHEMA_EMAILPREF_ERRORS;

  const handleSubmit = form.createSubmitHandler((v) => {
    setSubmitting(true);

    subscribe
      .mutateAsync({
        ...v,
        searchResultsUrlRelativeReference: "/opportunities/list",
      } as unknown as IEmailPreferencesRequest)
      .then((res) => {
        if (res === null) {
          toast.success("Email preferences updated");
        } else {
          toast.error("Something went wrong");
        }
        setSubmitting(false);
      });
  });

  useEffect(() => {
    if (!token) return;

    const url = new URL(
      `${WidgetApiUrl}/preferences/${encodeURIComponent(String(token))}`
    );

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        form.setValue("email", data.email);
        form.setValue("filters.suitabilities", data.suitabilities.join(","));
        form.setValue("filters.interestAreas", data.interestAreas.join(","));
      });
  }, []);

  return (
    <Layout>
      <Hero />
      <Section bgColor="offWhite">
        <div id="email-preferences" className="row">
          <h1 className="h2 fw-normal text-success mb-4">
            Update your email preferences
          </h1>
          <p className="mb-0">
            This subscription emails you new youth volunteer opportunities
            you&apos;re interested in. <br />
            Update what interests you below.
          </p>
        </div>
        <div className="row">
          <form.FormProvider>
            <form action="POST" noValidate onSubmit={handleSubmit}>
              <div className="col-12 col-md-9 mb-3">
                <Form.Field
                  {...form.field}
                  id="email"
                  name="email"
                  label=""
                  type="email"
                  value={values.email || ""}
                  readOnly
                  className=""
                />
              </div>

              <div className="mb-4">
                <h2 className="h4 fw-bold mb-2">Age bracket</h2>
                <Form.RadioInput
                  {...form.field}
                  isInline
                  id="filters.suitabilities"
                  title=""
                  value={
                    values.filters.suitabilities
                      .split(",")
                      .filter((n) => n !== SUITABILITIES.WORK_EXPERIENCE)
                      .join(",") || ""
                  }
                  touched={!!touched.filters?.suitabilities}
                  error={errors.filters?.suitabilities}
                  options={[
                    {
                      text: "18-24 years old",
                      value: SUITABILITIES.YOUNG_ADULTS,
                    },
                    {
                      text: "14-17 years old",
                      value: SUITABILITIES.YOUNGER_VOLUNTEERS,
                    },
                  ]}
                  onChange={(e) => {
                    const { value } = e.target;
                    const suitabilities = [
                      value,
                      SUITABILITIES.WORK_EXPERIENCE,
                    ].join(",");
                    form.setValue("filters.suitabilities", suitabilities, true);
                  }}
                />
              </div>

              <div className="mb-5">
                <h2 className="h4 fw-bold mb-2">Areas of interest</h2>
                <p className="fs-18 mb-4">Select one or more</p>
                <Form.CheckboxInput
                  {...form.field}
                  id="filters.interestAreas"
                  options={areaOfInterestList}
                  lgColumns={3}
                  className="mb-3"
                  selectedAnswers={values.filters.interestAreas
                    .split(",")
                    .map(Number)}
                  touched={!!touched.filters?.interestAreas}
                  error={errors.filters?.interestAreas}
                  onChange={(e) => {
                    const value = e.target.value;
                    let interestAreas = values.filters.interestAreas.split(",");
                    var index = interestAreas.indexOf(value);
                    if (index !== -1) {
                      interestAreas.splice(index, 1);
                    } else {
                      interestAreas.push(value);
                    }
                    form.setValue(
                      "filters.interestAreas",
                      interestAreas.join(","),
                      true
                    );
                  }}
                />
              </div>

              <hr className="mb-4 border-2" />

              <button
                type="submit"
                className="btn btn-primary me-4"
                disabled={submitting}
              >
                {submitting ? "Updating..." : "Update"}
              </button>

              <Link
                className="btn btn-link"
                to={`${Routes.Unsubscribe}?id=${encodeURIComponent(
                  String(token)
                )}`}
              >
                Unsubscribe
              </Link>
            </form>
          </form.FormProvider>
        </div>
      </Section>
    </Layout>
  );
};

export const Head = () => {
  return (
    <Meta
      title="Email preferences"
      description="Update your preferences to receive email notifications about new volunteering opportunities"
    />
  );
};
